.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.error {
  color: darkred;
}

.resultImage {
  width: 60dvw;
  height: auto;
  max-width: 600px;
}