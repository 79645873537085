.input {
    background-color: lightgray;
    color: black;
    padding: 10px 10px;
    border: none;
    border-radius: 5px;
    box-sizing: border-box;
    font-size: 16px;
    min-width: var(--element-width);
}

.input:focus-visible {
    outline: none;
}