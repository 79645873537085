.button {
    background-color: blue;
    color: white;
    padding: 10px 24px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    min-width: var(--element-width);
}
.button:hover {
    background-color: darkblue;
}